import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PerformanceReviewProvider extends HttpRequest {
  getPerformanceReview (query) {
    this.setHeader(getAuthToken())
    return this.get('performance-review', query)
  }

  getPerformanceReviewGroupByMonth (query) {
    this.setHeader(getAuthToken())
    return this.get('performance-review/by-monthes', query)
  }

  getPerformanceReviewById (id) {
    this.setHeader(getAuthToken())
    return this.get(`performance-review/${id}`)
  }

  createPerformanceReview (performanceReviewForm) {
    this.setHeader(getAuthToken())
    return this.post('performance-review', performanceReviewForm)
  }

  updatePerformanceReview (performanceReviewForm) {
    this.setHeader(getAuthToken())
    return this.put(`performance-review/${performanceReviewForm.id}`, performanceReviewForm)
  }

  deletePerformanceReview (id) {
    this.setHeader(getAuthToken())
    return this.delete(`performance-review/${id}`)
  }

  getPerformanceReport (query) {
    this.setHeader(getAuthToken())
    return this.get('performance-report', query)
  }

  getPickPackReport (query) {
    this.setHeader(getAuthToken())
    return this.get('performance-report/pick-pack', query)
  }
}

export default PerformanceReviewProvider
