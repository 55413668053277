import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductCollectionProvider extends HttpRequest {
  createProductCollection (payload) {
    this.setHeader(getAuthToken())
    return this.post('/product/collections', payload)
  }

  deleteProductCollection (queryId) {
    this.setHeader(getAuthToken())
    return this.delete('/product/collections', queryId)
  }

  updateProductCollection (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/product/collections/${param}`, payload)
  }

  getById (query) {
    this.setHeader(getAuthToken())
    return this.get('/product/collections', query)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/product/collections', query)
  }
}

export default ProductCollectionProvider
