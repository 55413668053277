import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductGroupCategoryProvider extends HttpRequest {
  createProductGroupCategory (payload) {
    this.setHeader(getAuthToken())
    return this.post('/product/group-categories', payload)
  }

  deleteProductGroupCategory (queryId) {
    this.setHeader(getAuthToken())
    return this.delete('/product/group-categories', queryId)
  }

  updateProductGroupCategory (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/product/group-categories/${param}`, payload)
  }

  getById (query) {
    this.setHeader(getAuthToken())
    return this.get('/product/group-categories', query)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/product/group-categories', query)
  }
}

export default ProductGroupCategoryProvider
