import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductAttributeProvider extends HttpRequest {
  getAllProductAttribute () {
    this.setHeader(getAuthToken())
    return this.get('/products/attributes')
  }

  getProductAttribute (param, options) {
    this.setHeader(getAuthToken())
    return this.getByPagination(`/product/${param}`, options)
  }

  getAllProductAttributeByAttribute (param) {
    this.setHeader(getAuthToken())
    return this.get(`/product/${param}?limit=9999999`)
  }

  getProductAttributeId (param, payload) {
    this.setHeader(getAuthToken())
    return this.post(`/product/${param}/get-ids`, payload)
  }

  createMultiProductAttribute (param, payload) {
    this.setHeader(getAuthToken())
    return this.post(`/product/${param}/get-ids`, payload)
  }

  createProductAttribute (param, payload) {
    this.setHeader(getAuthToken())
    return this.post(`/product/${param}`, payload)
  }

  updateProductAttribute (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/product/${param.path}/${param.id}`, payload)
  }

  deleteAttribute (param, id) {
    this.setHeader(getAuthToken())
    return this.delete(`/product/${param}?id=${id}`)
  }
}

export default ProductAttributeProvider
