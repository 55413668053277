<template>
  <v-container
    class="pms__performanceReport__container"
    fluid>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center">
        <h2>Performance Report</h2>
        <v-btn
          class="ml-1"
          color="success"
          icon
          :loading="exporting"
          @click="exportCsv">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row
          class="pt-3"
          justify="end">
          <v-col
            cols="12"
            lg="auto"
            class="d-flex justify-center align-end">
            <date-picker
              v-model="query.date"
              type="month" />
          </v-col>
          <v-col
            cols="12"
            lg="8"
            class="d-flex justify-center align-end">
            <search-by-collections
              v-model="query"
              tags-width="300px"
              :tags="groupCategories"
              :collections="groupCollections"
              @on-search="getPerformanceReports()" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :items="fetchReport"
          :headers="headers"
          :loading="loading"
          :items-per-page="-1"
          hide-default-footer>
          <template #[`item.salesTotal`]="{ item }">
            {{ item.salesTotal | showFullPriceFormat() }} บาท
          </template>
          <template #[`item.salesTarget`]="{ item }">
            {{ item.salesTarget | showFullPriceFormat() }} บาท
          </template>
          <template #[`item.salesToTarget`]="{ item }">
            <span v-if="item.salesToTarget === 100">
              {{ item.salesToTarget | showFullPriceFormat() }}%
            </span>
            <span
              v-else-if="item.salesToTarget < 100"
              style="color: #ef5350">
              {{ item.salesToTarget | showFullPriceFormat() }}%
            </span>
            <span
              v-else
              style="color: #2e7d32">
              +{{ Math.abs(item.salesToTarget) | showFullPriceFormat() }}%
            </span>
          </template>
          <template #[`item.totalScore`]="{ item }">
            <span v-if="item.totalScore === 0">
              {{ item.totalScore | showFullPriceFormat() }}%
            </span>
            <span
              v-else-if="item.totalScore >= 0"
              style="color: #2e7d32">
              {{ item.totalScore | showFullPriceFormat() }}%
            </span>
            <span
              v-else
              style="color: #ef5350">
              {{ item.totalScore | showFullPriceFormat() }}%
            </span>
          </template>
          <template #[`item.pool`]="{ item }">
            {{ item.pool | showFullPriceFormat() }} บาท
          </template>
          <template #[`item.bonus`]="{ item }">
            {{ item.bonus | showFullPriceFormat() }} บาท
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import { mapActions } from 'vuex'
import DatePicker from '@/components/DatePicker.vue'
import SearchByCollections from '@/components/SearchByCollections.vue'
import PerformanceReviewProvider from '@/resources/PerformanceReviewProvider'
import ProductGroupCategoryProvider from '@/resources/ProductGroupCategoryProvider'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'

const ProductCollectionService = new ProductCollectionProvider()
const ProductGroupCategoryService = new ProductGroupCategoryProvider()
const PerformanceReviewService = new PerformanceReviewProvider()

export default {
  components: {
    DatePicker,
    SearchByCollections
  },
  data () {
    return {
      fetchReport: [],
      groupCollections: [
        {
          id: '',
          name: 'All'
        }
      ],
      groupCategories: [],
      query: {
        gwCollection: '',
        warehouse: null,
        tags: [],
        tagOperation: 'OR',
        date: this.$dayjs().format('YYYY-MM')
      },
      headers: [
        {
          text: 'Store',
          value: 'name',
          align: 'start',
          width: '180px',
          sortable: false
        },
        {
          text: 'Total',
          value: 'salesTotal',
          align: 'end',
          width: '180px',
          sortable: false
        },
        {
          text: 'Target',
          value: 'salesTarget',
          align: 'end',
          width: '180px',
          sortable: false
        },
        {
          text: '% Sales to target',
          value: 'salesToTarget',
          align: 'center',
          width: '180px',
          sortable: false
        }
      ],
      loading: false,
      exporting: false
    }
  },
  async mounted () {
    await Promise.all([
      this.getGroupCategories(),
      this.getGroupCollections()
    ])
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getGroupCollections () {
      try {
        this.loading = true

        const { data } = await ProductCollectionService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'priority',
          sortOrder: 'asc'
        })

        this.groupCollections = [
          ...this.groupCollections,
          ...data.results
        ]
      } catch (error) {
        console.error('getGroupCollections', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING GROUP COLLECTIONS]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getGroupCategories () {
      try {
        this.loading = true

        const { data } = await ProductGroupCategoryService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'priority',
          sortOrder: 'asc'
        })

        this.groupCategories = [...data.results]
      } catch (error) {
        console.error('getGroupCategories', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING GROUP CATEGORIES]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getPerformanceReports () {
      try {
        this.loading = true

        this.getHeaders()

        const { data: performanceReport } = await PerformanceReviewService.getPerformanceReport({
          gwCollection: this.query.gwCollection,
          warehouse: this.query.warehouse,
          gwCategories: this.query.tags,
          tagOperation: this.query.tagOperation,
          startDate: this.$dayjs(this.query.date)
                      .date(1)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .utc()
                      .toISOString(),
          endDate: this.$dayjs(this.query.date)
                      .add(1, 'month')
                      .date(1)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .utc()
                      .toISOString()
        })

        this.fetchReport = performanceReport.warehouses
          .map((report) => {
            const mapped = {
              id: report.id,
              name: report.name,
              salesTotal: report.salesTotal,
              salesTarget: report.salesTarget,
              salesToTarget: report.salesTarget ? this.getRemainPercent(report.salesTarget, report.salesTotal) : 0,
              pool: report.pool,
              bonus: report.bonus,
              totalScore: report.totalScore
            }

            report.gwCategories.forEach((gwCategory) => {
              if (gwCategory?.actualSales && gwCategory?.actualSales >= 0) {
                mapped[`${gwCategory.name}`] = `${this.$options.filters.showFullPriceFormat(gwCategory?.actualSales)} บาท`
              } else {
                mapped[`${gwCategory.name}`] = '-'
              }
            })

            return mapped
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      } catch (error) {
        console.error('getPerformanceReports', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING PERFORMANCE REPORTS]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getHeaders () {
      if (this.query.tags.length) {
        this.headers = [
          {
            text: 'Store',
            value: 'name',
            align: 'start',
            width: '180px',
            sortable: false
          },
          {
            text: 'Total',
            value: 'salesTotal',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: 'Target',
            value: 'salesTarget',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: '% Sales to target',
            value: 'salesToTarget',
            align: 'center',
            width: '180px',
            sortable: false
          },
          ...this.query.tags.map((cat) => ({
              text: cat,
              value: cat,
              align: 'end',
              width: '180px',
              sortable: false
            })),
          {
            text: 'PMS',
            value: 'totalScore',
            align: 'center',
            width: '180px',
            sortable: false
          },
          {
            text: 'Bonus Pool',
            value: 'pool',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: 'Net Bonus Pool',
            value: 'bonus',
            align: 'end',
            width: '180px',
            sortable: false
          }
        ]
      } else {
        this.headers = [
          {
            text: 'Store',
            value: 'name',
            align: 'start',
            width: '180px',
            sortable: false
          },
          {
            text: 'Total',
            value: 'salesTotal',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: 'Target',
            value: 'salesTarget',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: '% Sales to target',
            value: 'salesToTarget',
            align: 'center',
            width: '180px',
            sortable: false
          },
          ...this.groupCategories.map((cat) => ({
              text: cat.name,
              value: cat.name,
              align: 'end',
              width: '180px',
              sortable: false
            })),
          {
            text: 'Others',
            value: 'Others',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: 'PMS',
            value: 'totalScore',
            align: 'center',
            width: '180px',
            sortable: false
          },
          {
            text: 'Bonus Pool',
            value: 'pool',
            align: 'end',
            width: '180px',
            sortable: false
          },
          {
            text: 'Net Bonus Pool',
            value: 'bonus',
            align: 'end',
            width: '180px',
            sortable: false
          }
        ]
      }
    },
    getRemainPercent (a, b) {
      return 100 / (a / b)
    },
    getRandomNumber (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    async exportCsv () {
      try {
        this.exporting = true

        const { data: performanceReport } = await PerformanceReviewService.getPerformanceReport({
          gwCollection: this.query.gwCollection,
          warehouse: this.query.warehouse,
          gwCategories: this.query.tags,
          tagOperation: this.query.tagOperation,
          startDate: this.$dayjs(this.query.date)
                      .date(1)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .utc()
                      .toISOString(),
          endDate: this.$dayjs(this.query.date)
                      .add(1, 'month')
                      .date(1)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .utc()
                      .toISOString()
        })

        const mappedReport = performanceReport.warehouses
          .map((report) => {
            const mapped = {
              name: report.name,
              salesTotal: Number(report.salesTotal).toLocaleString(),
              salesTarget: Number(report.salesTarget).toLocaleString(),
              salesToTarget: Number(report.salesTarget ? this.getRemainPercent(report.salesTarget, report.salesTotal) : 0).toLocaleString(),
              pool: Number(report.pool).toLocaleString(),
              bonus: Number(report.bonus).toLocaleString(),
              totalScore: Number(report.totalScore).toLocaleString()
            }

            report.gwCategories.forEach((gwCategory) => {
              if (gwCategory?.actualSales && gwCategory?.actualSales >= 0) {
                mapped[`${gwCategory.name}`] = Number(gwCategory?.actualSales).toLocaleString()
              } else {
                mapped[`${gwCategory.name}`] = '-'
              }
            })

            return mapped
          })
          .sort((a, b) => a.name.localeCompare(b.name))

        const options = {
          filename: `Performance_Report_${this.query.date}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(mappedReport)
      } catch (error) {
        console.error('exportCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.pms__performanceReport__container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
