var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pms__performanceReport__container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Performance Report")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"success","icon":"","loading":_vm.exporting},on:{"click":_vm.exportCsv}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-3",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-center align-end",attrs:{"cols":"12","lg":"auto"}},[_c('date-picker',{attrs:{"type":"month"},model:{value:(_vm.query.date),callback:function ($$v) {_vm.$set(_vm.query, "date", $$v)},expression:"query.date"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-end",attrs:{"cols":"12","lg":"8"}},[_c('search-by-collections',{attrs:{"tags-width":"300px","tags":_vm.groupCategories,"collections":_vm.groupCollections},on:{"on-search":function($event){return _vm.getPerformanceReports()}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.fetchReport,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.salesTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.salesTotal))+" บาท ")]}},{key:"item.salesTarget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.salesTarget))+" บาท ")]}},{key:"item.salesToTarget",fn:function(ref){
var item = ref.item;
return [(item.salesToTarget === 100)?_c('span',[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.salesToTarget))+"% ")]):(item.salesToTarget < 100)?_c('span',{staticStyle:{"color":"#ef5350"}},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.salesToTarget))+"% ")]):_c('span',{staticStyle:{"color":"#2e7d32"}},[_vm._v(" +"+_vm._s(_vm._f("showFullPriceFormat")(Math.abs(item.salesToTarget)))+"% ")])]}},{key:"item.totalScore",fn:function(ref){
var item = ref.item;
return [(item.totalScore === 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.totalScore))+"% ")]):(item.totalScore >= 0)?_c('span',{staticStyle:{"color":"#2e7d32"}},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.totalScore))+"% ")]):_c('span',{staticStyle:{"color":"#ef5350"}},[_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.totalScore))+"% ")])]}},{key:"item.pool",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.pool))+" บาท ")]}},{key:"item.bonus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.bonus))+" บาท ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }